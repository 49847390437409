import React from 'react'

const AboutDetails = () => {
  return (
    <>
            <div className="upper-box">
            <div className="boxcar-container">
                <div className="row wow fadeInUp">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="boxcar-title">
                            <ul className="breadcrumb">
                                <li><a href="#">Home</a></li>
                                <li><span>About us</span></li>
                            </ul>
                            <h2>About Us</h2>
                            <div className="text">We Value Our Clients 
                            And Want Them To Have 
                            A Nice Experience</div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="content-box">
                            <div className="text">Lorem ipsum dolor sit amet consectetur. Convallis integer enim eget sit urna. Eu duis lectus amet vestibulum varius. Nibh tellus sit sit at lorem facilisis. Nunc vulputate ac interdum aliquet 
                                vestibulum in tellus. 
                            </div>
                            <div className="text">Sit convallis rhoncus dolor purus amet orci urna. Lobortis vulputate 
                                vestibulum consectetur donec ipsum egestas velit laoreet justo. Eu dignissim egestas egestas 
                                ipsum. Sit est nunc pellentesque at a aliquam ultrices consequat. Velit duis velit nec amet eget 
                                eu morbi. Libero non diam sit viverra dignissim. Aliquam tincidunt in cursus euismod enim.
                            </div>
                            <div className="text">Magna odio sed ornare ultrices. Id lectus mi amet sit at sit arcu mi nisl. Mauris 
                                egestas arcu mauris.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutDetails
