import React, { useEffect, useState } from "react";
import car1 from "../assets/images/car/A1-Coupe.jpg";
import { SketchPicker } from "react-color";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const CarDetails = ({ CarDetail }) => {
  const [color, setColor] = useState("#ffffff");
  const [showPicker, setShowPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [customColors, setCustomColors] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate(); // Initialize navigate

  const handleDivClick = () => {
    setShowPicker(!showPicker);
  };

  useEffect(() => {
    if (CarDetail && CarDetail.car_model_color && CarDetail.car_model_color[0]) {
      setSelectedColor(CarDetail.car_model_color[0].color);
    }
  }, [CarDetail]);

  const handleColorChange = (color) => {
    setColor(color.hex);
    setShowPicker(false);
    setCustomColors((prevColors) => [...prevColors, color.hex]);
    setSelectedColor(color.hex);
  };

  const handleColorSelect = (selected) => {
    setSelectedColor(selected);
  };

  const handleAddToCart = () => {
    const selectedCarDetail = {
      modelName: CarDetail?.car_model_name,
      description: CarDetail?.car_model_description,
      selectedColor,
      quantity,
      price: CarDetail?.car_model_color?.find((data) => data.color === selectedColor)?.price || 0,
    };

    // Navigate to the cart and pass the selectedCarDetail as state
    navigate("/cart", { state: { selectedCarDetail } });
  };

  return (
    <div className="cars-section-fifteen">
      <div className="row">
        <div className="image-column col-lg-7 col-md-12 col-sm-12">
          <div className="inner-column">
            <div className="shop-single-product-slider">
              <div className="shop-single-product-slide">
                <img src={car1} alt="Car Model" />
              </div>
            </div>
          </div>
        </div>

        <div className="content-column col-lg-5 col-md-12 col-sm-12 mx-1 mx-sm-2 mx-md-5 mx-lg-0">
          <div className="inner-column">
            <h2>{CarDetail?.car_model_name}</h2>
            {CarDetail?.car_model_color?.map(
              (data, index) =>
                data.color === selectedColor && (
                  <span className="price" key={index}>
                    ₹{data.price}
                  </span>
                )
            )}

            <div className="text">{CarDetail?.car_model_description}</div>
            <div className="colors row">
              {CarDetail?.car_model_color?.map((data, index) => (
                <div key={`existing-color-${index}`} className="col-auto p-0 mb-2">
                  <div
                    className="color"
                    style={{
                      backgroundColor: data.color,
                      border:
                        data.color === selectedColor
                          ? "3px solid #000"
                          : "1px solid #ccc",
                      boxSizing: "border-box",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    data-hex={data.color}
                    onClick={() => handleColorSelect(data.color)}
                    title={data.color}
                  ></div>
                </div>
              ))}

              {customColors.map((customColor, index) => (
                <div key={`custom-color-${index}`} className="col-auto p-0 mb-2">
                  <div
                    className="color"
                    style={{
                      backgroundColor: customColor,
                      border:
                        customColor === selectedColor
                          ? "3px solid #000"
                          : "1px solid #ccc",
                      boxSizing: "border-box",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    data-hex={customColor}
                    onClick={() => handleColorSelect(customColor)}
                    title={customColor}
                  ></div>
                </div>
              ))}

              <div className="col-auto p-0 mb-2">
                <div
                  className="color"
                  style={{
                    backgroundColor: color,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #000",
                    boxSizing: "border-box",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  data-hex={color}
                  onClick={handleDivClick}
                  title="Add a new color"
                >
                  +
                </div>

                {showPicker && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      top: "40px",
                      left: "0",
                    }}
                  >
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                      }}
                      onClick={() => setShowPicker(false)}
                    />
                    <SketchPicker
                      color={color}
                      onChangeComplete={handleColorChange}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="number mb-5">
              <span
                className="minus"
                onClick={() => setQuantity((prev) => Math.max(prev - 1, 1))}
              >
                -
              </span>
              <input type="text" value={quantity} readOnly />
              <span
                className="plus"
                onClick={() => setQuantity((prev) => prev + 1)}
              >
                +
              </span>
            </div>

            <div className="btn-box">
              <button className="shoping-btn" onClick={handleAddToCart}>
                <i className="fa-solid fa-cart-shopping"></i> Add To Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarDetails;
