import React from "react";
import MainHeader from "../common/MainHeader";
import Footer from "../common/Footer";
import AboutDetails from "./About/AboutDetails";
import AboutCarBrands from "./About/AboutCarBrands";
import WhyChooseUs from "./About/WhyChooseUs";
import AboutGallery from "./About/AboutGallery";
import AboutTeam from "./About/AboutTeam";

const AboutUs = () => {
  return (
    <>
      <div className="boxcar-wrapper">
        <MainHeader />
        <section className="about-inner-one layout-radius">
          <AboutDetails />
          <AboutGallery/>
          <WhyChooseUs/>
          <AboutCarBrands/>
          {/* <AboutTeam/> */}
        </section>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
