import React from "react";
import MainHeader from "../common/MainHeader";
import Footer from "../common/Footer";
import profile from "../assets/images/resource/vehical-auther1-1.png";
import cart1 from '../assets/images/resource/cart1.png'
import cart2 from '../assets/images/resource/cart2.png'
import cart3 from '../assets/images/resource/cart3.png'
const Profile = () => {
  return (
    <>
      <div className="boxcar-wrapper">
        <MainHeader />
        <section className="contact-us-section layout-radius">
          <div className="boxcar-container">
            <div className="right-box">
              <div className="boxcar-title wow fadeInUp">
                <ul className="breadcrumb">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <span>Profile</span>
                  </li>
                </ul>
                <h2>Profile</h2>
              </div>
              <div className="calculater-sec p-0 mb-3">
                <div className="right-box">
                  <div className="row">
                    <div className="col-lg-12 content-column">
                      <div className="inner-column">
                        <form className="row">
                          <div className="col-lg-4">
                            <div className="form_boxes">
                              <label>Username</label>
                              <input
                                type="text"
                                name="name"
                                placeholder="AlUsernamei"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form_boxes">
                              <label>Email</label>
                              <input
                                type="email"
                                name="email"
                                placeholder="Creativelayers088@Gmail.Com"
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                          <div className="form_boxes">
                              <label>Phone</label>
                              <input
                                type="number"
                                name="number"
                                placeholder="+90 47458 27 3287 12"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form_boxes">
                              <label>Password</label>
                              <input
                                type="Password"
                                name="number"
                                placeholder="******"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form_boxes">
                              <label>Confirm Password</label>
                              <input
                                type="Password"
                                name="number"
                                 placeholder="******"
                              />
                            </div>
                          </div>
                          <div className="form-submit">
                            <button type="submit" className="theme-btn">
                              Update Profile
                              <img src="images/arrow.svg" alt="" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
        <div className="col-lg-12">
            <div className="cart-table">
                <table>
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Subtotal</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="shop-cart-product">
                                    <div className="shop-product-cart-img">
                                        <img src={cart1} alt="" />
                                    </div>
                                    <div className="shop-product-cart-info">
                                        <h3><a href="#" title="">12v Compact Battery Jump Starter</a></h3>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span className="price">$298</span>
                            </td>
                            <td>
                                <span className="price">1</span>
                            </td>
                            <td>
                                <span className="price">$1.298</span>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                <div className="shop-cart-product">
                                    <div className="shop-product-cart-img">
                                        <img src={cart2} alt="" />
                                    </div>
                                    <div className="shop-product-cart-info">
                                        <h3><a href="#" title="">Spectre Performance SPE-9055</a></h3>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span className="price">$298</span>
                            </td>
                            <td>
                                <span className="price">2</span>
                            </td>
                            <td>
                                <span className="price">$1.298</span>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>
                                <div className="shop-cart-product">
                                    <div className="shop-product-cart-img">
                                        <img src={cart3} alt="" />
                                    </div>
                                    <div className="shop-product-cart-info">
                                        <h3><a href="#" title="">Road and Track Suspensio</a></h3>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span className="price">$298</span>
                            </td>
                            <td>
                                <span className="price">1</span>
                            </td>
                            <td>
                                <span className="price">$1.298</span>
                            </td>
                            
                        </tr>
                    </tbody>
                </table> 
            </div>
        </div>
    </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
