import React from 'react'
import Header from '../common/Header'
import IndexSlider from './Home/IndexSlider'
import HomeSection3 from './Home/HomeSection3'
import AboutCarBrands from './About/AboutCarBrands'
import WhyChooseUs from './About/WhyChooseUs'
import Footer from '../common/Footer'
import HomeBanner from './Home/HomeBanner'
import HomeSection2 from './Home/HomeSection2'

const Home = () => {
  return (
    <>
    <div className="boxcar-wrapper">

      <Header/>
      <IndexSlider/>
      <AboutCarBrands/>
      <HomeBanner/>
      <WhyChooseUs/>
      <HomeSection2/>
      <HomeSection3/>
      <Footer/>
    </div>
    </>
  )
}

export default Home
