import React, { useEffect, useState } from "react";
import image1 from "../../assets/images/resource/brand-1.png";
// import image2 from "../../assets/images/resource/brand-2.png";
// import image3 from "../../assets/images/resource/brand-3.png";
// import image4 from "../../assets/images/resource/brand-4.png";
// import image5 from "../../assets/images/resource/brand-5.png";
// import image6 from "../../assets/images/resource/brand-6.png";
import { Link } from "react-router-dom";
import axios from "axios";
const AboutCarBrands = () => {
  const [AllBrands, setAllBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getAllCarBrands();
  }, []);
  const getAllCarBrands = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://api.snppaints.in/user/getAllCarBrands", {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjE5Mjg0MDR9.oX5UmQKuyaS1EhbyWK3xZcCeIXPeNomslYOuc6oUgQI",
        },
      });
      console.log(response.data);
      setAllBrands(response.data.brands.slice(0, 6));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        setAllBrands([]);
      } else {
        console.error("An error occurred while fetching brands.");
      }
    }
  };
  // const CarBrandDetails = [
  //   {
  //     img: image1,
  //     title: "Audi",
  //   },
  //   {
  //     img: image2,
  //     title: "BMW",
  //   },
  //   {
  //     img: image3,
  //     title: "ford",
  //   },
  //   {
  //     img: image4,
  //     title: "Mercedes Benz",
  //   },
  //   {
  //     img: image5,
  //     title: "Peugeot",
  //   },
  //   {
  //     img: image6,
  //     title: "Volkswagen",
  //   },
  // ];

  console.log(AllBrands, "AllBrands");

  return (
    <>
      {!loading && (
        <>
          {AllBrands.length && (
            <div className="boxcar-brand-section">
              <div className="boxcar-container">
                <div className="boxcar-title">
                  <h2 className="wow fadeInUp">Explore Our Premium Brands</h2>
                  <Link to="/car_brand" className="btn-title">
                    Show All Brands
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewbox="0 0 14 14"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_601_3199)">
                        <path
                          d="M13.6109 0H5.05533C4.84037 0 4.66643 0.173943 4.66643 0.388901C4.66643 0.603859 4.84037 0.777802 5.05533 0.777802H12.6721L0.113697 13.3362C-0.0382246 13.4881 -0.0382246 13.7342 0.113697 13.8861C0.18964 13.962 0.289171 14 0.388666 14C0.488161 14 0.587656 13.962 0.663635 13.8861L13.222 1.3277V8.94447C13.222 9.15943 13.3959 9.33337 13.6109 9.33337C13.8259 9.33337 13.9998 9.15943 13.9998 8.94447V0.388901C13.9998 0.173943 13.8258 0 13.6109 0Z"
                          fill="#050B20"
                        ></path>
                      </g>
                      <defs>
                        <clippath id="clip0_601_3199">
                          <rect width="14" height="14" fill="white"></rect>
                        </clippath>
                      </defs>
                    </svg>
                  </Link>
                </div>
                <div className="row">
                  {AllBrands?.map((data) => {
                    return (
                      <div className="cars-block col-lg-2 col-md-6 col-sm-6">
                        <div className="inner-box wow fadeInUp">
                          <div className="image-box">
                            <figure className="image">
                              <Link
                                to={`/car_brand/${data?.brand_name}`}
                                state={{
                                  id: data?._id,
                                  brand_name: data?.brand_name,
                                }}
                              >
                                <img src={image1} alt="" />
                              </Link>
                            </figure>
                          </div>
                          <div className="content-box">
                            <h6 className="title">
                              <a href="#">{data?.brand_name}</a>
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AboutCarBrands;
