import React,{useEffect} from 'react'

const Loader = ({loading}) => {
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = 'hidden'; // Disable scroll
    } else {
      document.body.style.overflow = ''; // Enable scroll
    }

    return () => {
      document.body.style.overflow = ''; // Clean up on unmount or loader hidden
    };
  }, [loading]);

  // if (!loading) return null;

  return (
    <>
       <div className="loader-wrapper">
      <div className="loader"></div>
    </div>
    </>
  )
}

export default Loader
