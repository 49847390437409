import React, { useEffect, useState } from "react";
import MainHeader from "../common/MainHeader";
import Footer from "../common/Footer";
import axios from "axios";
import image1 from "../assets/images/resource/deal1-1.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../common/Loader";
import CarDetails from "./CarDetails";
const ITEMS_PER_PAGE = 12;

const CarBrand = () => {
  const location = useLocation();
  console.log(location, "loooo");

  const [loading, setLoading] = useState(true);
  const { brandName, maincarid, car_detail } = useParams();
  const [AllBrands, setAllBrands] = useState([]);
  const [MainAllCar, setMainAllCar] = useState([]);
  const [SubAllCar, setSubAllCar] = useState([]);
  const [MainCarId, setMainCarId] = useState("");
  const [SubCarId, setSubCarId] = useState("");
  const [MainCarName, setMainCarName] = useState("");
  const [SubCarName, setSubCarName] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([
    { name: "Home", path: "/" },
    { name: "Car Brands", path: "/car_brand" },
  ]);
  const [title, setTitle] = useState("Car Brand List");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [CarDetail, setCarDetail] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state) {
      setMainCarId(location.state.id);
      // setTitle(brandName);

      setMainCarName(location.state.name);
      setTitle(location.state.name);
      setBreadcrumbs([
        { name: "Home", path: "/" },
        { name: "Car Brands", path: "/car_brand" },
        {
          name: location.state.name,
          path: `/car_brand/${location.state.name}`,
        },
      ]);
      getBrandWiseCar(location.state.id);
    } else {
      setBreadcrumbs([
        { name: "Home", path: "/" },
        { name: "Car Brands", path: "/car_brand" },
      ]);
      navigate(`/car_brand`);
    }
  }, []);
  useEffect(() => {
    if (car_detail) {
      setTitle(car_detail); // Set a title for car details
    } else if (maincarid) {
      getMainCarWiseSubCar();
      setTitle(maincarid); // Set a title for car details
    } else if (brandName) {
      getBrandWiseCar(MainCarId);
      setTitle(brandName); // Set the title to the brand name from the URL
    } else {
      getAllCarBrands();
      setTitle("Car Brand List"); // Default title
    }
  }, [brandName, maincarid, car_detail]);
  useEffect(() => {
    // Update current data and total pages based on data length
    const data = maincarid ? SubAllCar : brandName ? MainAllCar : AllBrands;
    setCurrentData(getPaginatedData(data));
    setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
  }, [AllBrands, MainAllCar, SubAllCar, currentPage, brandName, maincarid]);
  const getAllCarBrands = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://api.snppaints.in/user/getAllCarBrands", {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjE5Mjg0MDR9.oX5UmQKuyaS1EhbyWK3xZcCeIXPeNomslYOuc6oUgQI",
        },
      });
      console.log(response.data);
      setAllBrands(response.data.brands);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        setAllBrands([]);
      } else {
        console.error("An error occurred while fetching brands.");
      }
    }
  };
  const getBrandWiseCar = async (brand) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.snppaints.in/user/getSelectedBrandCar/${brand}`,
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjE5Mjg0MDR9.oX5UmQKuyaS1EhbyWK3xZcCeIXPeNomslYOuc6oUgQI",
          },
        }
      );
      console.log(response.data, "mainCarrrrrrrrrrr");
      setMainAllCar(response.data.cars);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        setMainAllCar([]);
      } else {
        console.error("An error occurred while fetching cars.");
      }
    }
  };
  const getMainCarWiseSubCar = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.snppaints.in/user/getEachCarModelDetails/${SubCarId}`,
        {
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MjE5Mjg0MDR9.oX5UmQKuyaS1EhbyWK3xZcCeIXPeNomslYOuc6oUgQI",
          },
        }
      );
      console.log(response.data, "subCarrrrrrrrrrrrrrrrrrr");
      setSubAllCar(response.data.carDetails);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        setSubAllCar([]);
      } else {
        console.error("An error occurred while fetching sub-cars.");
      }
    }
  };
  const handleBrandClick = (brand) => {
    console.log(brand, "brandbrand");
    setMainCarId(brand?._id);
    setMainCarName(brand?.brand_name);
    setTitle(brand?.brand_name);
    setBreadcrumbs([
      { name: "Home", path: "/" },
      { name: "Car Brands", path: "/car_brand" },
      { name: brand?.brand_name, path: `/car_brand/${brand?.brand_name}` },
    ]);
    navigate(`/car_brand/${brand.brand_name}`);
  };
  const handleCarClick = (car) => {
    console.log(car, "carcarcarcar");
    setSubCarId(car?._id);
    setTitle(car?.car_name);
    setSubCarName(car?.car_name);
    setBreadcrumbs((prevBreadcrumbs) => [
      ...prevBreadcrumbs,
      {
        name: car?.car_name,
        path: `/car_brand/${MainCarName}/${car?.car_name}`,
      },
    ]);
    navigate(`/car_brand/${MainCarName}/${car?.car_name}`);
  };
  const handleSubCarClick = (car) => {
    console.log(car, "carcarcarcar");
    setCarDetail(car);
    setTitle(car?.car_model_name);
    setBreadcrumbs((prevBreadcrumbs) => [
      ...prevBreadcrumbs,
      {
        name: car?.car_model_name,
        path: `/car_brand/${MainCarName}/${SubCarName}/${car?.car_model_name}`,
      },
    ]);
    navigate(`/car_brand/${MainCarName}/${SubCarName}/${car.car_model_name}`);
  };
  const handleBreadcrumbClick = (breadcrumb, index) => {
    navigate(breadcrumb.path);
    setTitle(breadcrumb.name);
    setBreadcrumbs(breadcrumbs.slice(0, index + 1));
  };
  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };
  const getPaginatedData = (data) => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return data.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };
  const renderPagination = () =>
    totalPages > 1 && (
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <a
              className="page-link"
              onClick={() => handlePaginationChange(currentPage - 1)}
              aria-label="Previous"
              style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer" }}
            >
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">Previous</span>
            </a>
          </li>
          {[...Array(totalPages).keys()].map((page) => (
            <li
              key={page}
              className={`page-item ${
                currentPage === page + 1 ? "active" : ""
              }`}
            >
              <a
                className="page-link"
                onClick={() => handlePaginationChange(page + 1)}
              >
                {page + 1}
              </a>
            </li>
          ))}
          <li
            className={`page-item ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <a
              className="page-link"
              onClick={() => handlePaginationChange(currentPage + 1)}
              aria-label="Next"
              style={{
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              }}
            >
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    );

  return (
    <>
      <div className={`boxcar-wrapper ${loading ? "overflow-hidden" : ""}`}>
        {loading && <Loader loading={loading} />}
        <>
          <MainHeader />
          <section className="dealer-ship-section layout-radius">
            <div className="boxcar-container">
              <div className="boxcar-title-three wow fadeInUp">
                <ul className="breadcrumb">
                  {breadcrumbs.map((breadcrumb, index) => (
                    <li key={index}>
                      <a
                        onClick={() => handleBreadcrumbClick(breadcrumb, index)}
                      >
                        {breadcrumb.name}
                      </a>
                    </li>
                  ))}
                </ul>
                <h2>{title}</h2>
                {/* <span>Showing 1 to 16 of 1559 vehicles</span> */}
              </div>
              {car_detail ? (
                <CarDetails CarDetail={CarDetail} />
              ) : (
                <>
                  <div className="row">
                    {maincarid ? (
                      SubAllCar?.length ? (
                        currentData?.map((data, index) => {
                          return (
                            <div
                              className="dealer-block col-lg-3 col-md-6 col-sm-12"
                              key={index}
                            >
                              <div
                                className="inner-box cursor-pointer"
                                onClick={() => handleSubCarClick(data)}
                              >
                                <div className="image-box">
                                  <figure className="image">
                                    <a>
                                      <img src={image1} alt="" />
                                    </a>
                                  </figure>
                                </div>
                                <div className="content-box">
                                  <h6 className="title">
                                    <a>{data?.car_model_name}</a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-center">No Car Found</p>
                      )
                    ) : brandName ? (
                      MainAllCar?.length ? (
                        currentData?.map((data, index) => {
                          return (
                            <div
                              className="dealer-block col-lg-3 col-md-6 col-sm-12"
                              key={index}
                            >
                              <div
                                className="inner-box cursor-pointer"
                                onClick={() => handleCarClick(data)}
                              >
                                <div className="image-box">
                                  <figure className="image">
                                    <a>
                                      <img src={image1} alt="" />
                                    </a>
                                  </figure>
                                </div>
                                <div className="content-box">
                                  <h6 className="title">
                                    <a>{data?.car_name}</a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-center">No Car Found</p>
                      )
                    ) : AllBrands?.length ? (
                      currentData?.map((data, index) => {
                        return (
                          <div
                            className="dealer-block col-lg-3 col-md-6 col-sm-12"
                            key={index}
                          >
                            <div
                              className="inner-box cursor-pointer"
                              onClick={() => handleBrandClick(data)}
                            >
                              <div className="image-box">
                                <figure className="image">
                                  <a>
                                    <img src={image1} alt="" />
                                  </a>
                                </figure>
                              </div>
                              <div className="content-box">
                                <h6 className="title">
                                  <a>{data?.brand_name}</a>
                                </h6>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-center">No Brand Found</p>
                    )}
                  </div>
                  <div className="pagination-sec">{renderPagination()}</div>
                </>
              )}
            </div>
          </section>
          <Footer />
        </>
      </div>
    </>
  );
};

export default CarBrand;
