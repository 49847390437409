import React, { useState } from "react";
import MainHeader from "../common/MainHeader";
import Footer from "../common/Footer";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import {jwtDecode} from 'jwt-decode';
const Login = () => {
  const [signInData, setSignInData] = useState({
    emailOrUsername: "",
    password: "",
  });

  const [signUpData, setSignUpData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    signIn: {},
    signUp: {},
  });

  const handleSignInChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors({ signIn: {}, signUp: {} });
    setSignInData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSignUpChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors({ signIn: {}, signUp: {} });
    setSignUpData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateSignIn = () => {
    let tempErrors = {};
    if (!signInData.emailOrUsername)
      tempErrors.emailOrUsername = "Email is required";
    if (!signInData.password) tempErrors.password = "Password is required";
    setErrors((prevErrors) => ({ ...prevErrors, signIn: tempErrors }));
    return Object.keys(tempErrors).length === 0;
  };

  const validateSignUp = () => {
    let tempErrors = {};
    if (!signUpData.firstName) tempErrors.firstName = "First Name is required";
    if (!signUpData.lastName) tempErrors.lastName = "Last Name is required";
    if (!signUpData.email) tempErrors.email = "Email is required";
    if (!signUpData.phone) tempErrors.phone = "Phone number is required";
    if (!signUpData.password) tempErrors.password = "Password is required";

    setErrors((prevErrors) => ({ ...prevErrors, signUp: tempErrors }));
    return Object.keys(tempErrors).length === 0;
  };

  const handleSignInSubmit = (e) => {
    e.preventDefault();
    if (validateSignIn()) {
      console.log("Sign-In Data:", signInData);
    }
  };

  const handleSignUpSubmit = (e) => {
    e.preventDefault();
    if (validateSignUp()) {
      console.log("Sign-Up Data:", signUpData);
      // Submit sign-up data
    }
  };

  const register = async () => {
    const data = {
      first_name: signUpData?.firstName,
      last_name: signUpData?.lastName,
      email: signUpData?.email,
      mobile_number: signUpData?.phone,
      password: signUpData?.password,
    };

    try {
      const response = await axios.post("https://api.snppaints.in/user/register", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
      if (response?.data?.status === true) {
        const notify = () => toast.success(response?.data?.message);
        notify();
      } else {
        const notify = () => toast.error(response?.data?.message);
        notify();
      }
    } catch (error) {
      console.error(error); // Handle the error
      const notify = () => toast.error("Internal server error");
      notify();
    }
  };
  const Login = async () => {
    const data = {
      email: signInData?.emailOrUsername,
      password: signInData?.password,
    };

    try {
      const response = await axios.post("https://api.snppaints.in/user/login", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
      if (response?.data?.status === true) {       
        const notify = () => toast.success(response?.data?.message);
        const token = response?.data?.token
        if(token){
          try {
            localStorage.setItem('token',token)
            // Decode the token to get user information
            const decoded = jwtDecode(token);
            console.log('Decoded Token:', decoded);

            if(decoded.user_id){
            localStorage.setItem('user_id',decoded.user_id)

            }
            // setUserData(decoded);
        } catch (error) {
            console.error('Error decoding token:', error);
        }
        }

        console.log(token,'token');
        
        notify();
      } else {
        const notify = () => toast.error(response?.data?.message);
        notify();
      }
    } catch (error) {
      console.error(error);
      const notify = () => toast.error("Internal server error");
      notify();
    }
  };

  return (
    <>
      <div className="boxcar-wrapper">
        <MainHeader />
        <section className="login-section layout-radius">
          <div className="inner-container">
            <div className="right-box">
              <div className="form-sec">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Sign in
                    </button>
                    <button
                      className="nav-link"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Register
                    </button>
                  </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                  {/* Sign-in form */}
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="form-box">
                      <form className="border-0" onSubmit={handleSignInSubmit}>
                        <div className="form_boxes">
                          <label>Email</label>
                          <input
                            type="text"
                            name="emailOrUsername"
                            placeholder="Enter email"
                            value={signInData.emailOrUsername}
                            onChange={handleSignInChange}
                          />
                          {errors.signIn.emailOrUsername && (
                            <span className="error">
                              {errors.signIn.emailOrUsername}
                            </span>
                          )}
                        </div>
                        <div className="form_boxes">
                          <label>Password</label>
                          <input
                            type="password"
                            name="password"
                            placeholder="********"
                            value={signInData.password}
                            onChange={handleSignInChange}
                          />
                          {errors.signIn.password && (
                            <span className="error">
                              {errors.signIn.password}
                            </span>
                          )}
                        </div>
                        {/* <div className="btn-box">
                          <label className="contain">
                            Remember
                            <input
                              type="checkbox"
                              name="rememberMe"
                              checked={signInData.rememberMe}
                              onChange={handleSignInChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <a href="#" className="pasword-btn">
                            Forgotten password?
                          </a>
                        </div> */}
                        <div className="form-submit">
                          <button
                            type="submit"
                            className="theme-btn"
                            onClick={Login}
                          >
                            Login <img src="images/arrow.svg" alt="" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  {/* Sign-up form */}
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div className="form-box two">
                      <form className="border-0" onSubmit={handleSignUpSubmit}>
                        <div className="form_boxes">
                          <label>First Name</label>
                          <input
                            type="text"
                            name="firstName"
                            placeholder="Enter first name"
                            value={signUpData.firstName}
                            onChange={handleSignUpChange}
                          />
                          {errors.signUp.firstName && (
                            <span className="error">
                              {errors.signUp.firstName}
                            </span>
                          )}
                        </div>
                        <div className="form_boxes">
                          <label>Last Name</label>
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Enter last name"
                            value={signUpData.lastName}
                            onChange={handleSignUpChange}
                          />
                          {errors.signUp.lastName && (
                            <span className="error">
                              {errors.signUp.lastName}
                            </span>
                          )}
                        </div>
                        <div className="form_boxes">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={signUpData.email}
                            onChange={handleSignUpChange}
                          />
                          {errors.signUp.email && (
                            <span className="error">{errors.signUp.email}</span>
                          )}
                        </div>
                        <div className="form_boxes">
                          <label>Phone</label>
                          <input
                            type="number"
                            name="phone"
                            placeholder="Enter phone number"
                            value={signUpData.phone}
                            onChange={handleSignUpChange}
                          />
                          {errors.signUp.phone && (
                            <span className="error">{errors.signUp.phone}</span>
                          )}
                        </div>
                        <div className="form_boxes">
                          <label>Password</label>
                          <input
                            type="password"
                            name="password"
                            placeholder="********"
                            value={signUpData.password}
                            onChange={handleSignUpChange}
                          />
                          {errors.signUp.password && (
                            <span className="error">
                              {errors.signUp.password}
                            </span>
                          )}
                        </div>
                        <div className="form-submit">
                          <button
                            type="submit"
                            className="theme-btn"
                            onClick={register}
                          >
                            Register <img src="images/arrow.svg" alt="" />
                          </button>
                        </div>
                        {/* <div className="btn-box">
                          <label className="contain">
                            I accept the privacy policy
                            <input
                              type="checkbox"
                              name="acceptPolicy"
                              checked={signUpData.acceptPolicy}
                              onChange={handleSignUpChange}
                            />
                            <span className="checkmark"></span>
                          </label>
                          {errors.signUp.acceptPolicy && (
                            <span className="error">
                              {errors.signUp.acceptPolicy}
                            </span>
                          )}
                        </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Login;
