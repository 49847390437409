import React from 'react'
import About1 from '../../assets/images/resource/about-inner1-1.jpg'
import About2 from '../../assets/images/resource/about-inner1-2.jpg'
import About3 from '../../assets/images/resource/about-inner1-3.jpg'
import About4 from '../../assets/images/resource/about-inner1-4.jpg'
import About5 from '../../assets/images/resource/about-inner1-5.jpg'
const AboutGallery = () => {
  return (
    <>
           <div className="galler-section">
            <div className="boxcar-container">
                <div className="row">
                    <div className="exp-block col-md-2 col-sm-12">
                        <div className="inner-box">
                            <div className="exp-box">
                                <h2 className="title">45</h2>
                                <div className="text">Years in
                                Business</div>
                            </div>
                            <div className="image-box">
                                <figure className="image"><img src={About1} alt=""/></figure>
                            </div>
                        </div>
                    </div>
                    <div className="image-block style-center col-md-5 col-sm-12">
                        <div className="image-box">
                            <figure className="image"><img src={About2} alt=""/></figure>
                        </div>
                    </div>
                    <div className="image-block col-md-5 col-sm-12">
                        <div className="image-box two">
                            <figure className="image"><img src={About3} alt=""/></figure>
                        </div>
                        <div className="row box-double-img">
                            <div className="image-block col-lg-5 col-5">
                                <div className="image-box">
                                    <figure className="image"><img src={About4} alt=""/></figure>
                                </div>
                            </div>
                            <div className="image-block col-lg-7 col-7">
                                <div className="image-box">
                                    <figure className="image"><img src={About5} alt=""/></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutGallery
