import React from "react";
import image1 from "../../assets/images/banner/bg-2.jpg";
import Slider from "react-slick";

const IndexSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000, // Slowed down autoplay speed to 5 seconds
    speed: 1000,
  };

  return (
    <section className="boxcar-banner-section-two">
      <div className="banner-slider">
        <div className="banner-slide">
          <img src={image1} alt="" />
          <div className="right-box">
            <div className="boxcar-container">
              <div className="content-box">
                <span className="sub-title" data-animation-in="fadeInDown">
                  $1204<small>/ Month</small>
                </span>
                <h1 data-animation-in="fadeInUp" data-delay-in="0.2">
                  Mercedes New E-Class
                </h1>
                <ul data-animation-in="fadeInUp" data-delay-in="0.2">
                  <li>
                    <i className="flaticon-gasoline-pump"></i>Petrol
                  </li>
                  <li>
                    <i className="flaticon-speedometer"></i>72,925 miles
                  </li>
                  <li>
                    <i className="flaticon-gearbox"></i>Automatic
                  </li>
                </ul>
                <a
                  href="#"
                  data-animation-in="fadeInUp"
                  data-delay-in="0.4"
                  className="banner-btn btn-anim"
                >
                  Learn More
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_634_1882)">
                      <path
                        d="M13.6109 0H5.05533C4.84037 0 4.66643 0.173943 4.66643 0.388901C4.66643 0.603859 4.84037 0.777802 5.05533 0.777802H12.6721L0.113697 13.3362C-0.0382246 13.4881 -0.0382246 13.7342 0.113697 13.8861C0.18964 13.962 0.289171 14 0.388666 14C0.488161 14 0.587656 13.962 0.663635 13.8861L13.222 1.3277V8.94447C13.222 9.15943 13.3959 9.33337 13.6109 9.33337C13.8259 9.33337 13.9998 9.15943 13.9998 8.94447V0.388901C13.9998 0.173943 13.8258 0 13.6109 0Z"
                        fill="#050B20"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_634_1882">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndexSlider;
