    import React, { useState, useEffect } from 'react';
    import { Link, useLocation, useNavigate } from 'react-router-dom';
    import MainHeader from '../common/MainHeader';
    import Footer from '../common/Footer';
    import remove from '../assets/images/icons/remove.svg';

    const Cart = () => {
        const location = useLocation();
        const navigate = useNavigate();
        const [selectedCarDetail, setSelectedCarDetail] = useState(location.state?.selectedCarDetail || null);

        const [cart, setCart] = useState(() => {
            const storedCart = localStorage.getItem('cart');
            return storedCart ? JSON.parse(storedCart) : [];
        });

        useEffect(() => {
            if (selectedCarDetail) {
                // Check if the selectedCarDetail is already in the cart
                const isAlreadyInCart = cart.some(
                    item => item.car_model_name === selectedCarDetail.car_model_name && item.selectedColor === selectedCarDetail.selectedColor
                );

                if (!isAlreadyInCart) {
                    const newCart = [...cart, selectedCarDetail];
                    setCart(newCart);
                    localStorage.setItem('cart', JSON.stringify(newCart));
                    
                    // Clear selectedCarDetail after adding to cart
                    setSelectedCarDetail(null);
                    
                    // Navigate to the same route but clear state
                    navigate(location.pathname, { replace: true });
                }
            }
        }, [selectedCarDetail]);

        useEffect(() => {
            // Update localStorage whenever the cart changes
            localStorage.setItem('cart', JSON.stringify(cart));
        }, [cart]);

        const handleRemoveItem = (indexToRemove) => {
            const newCart = cart.filter((_, index) => index !== indexToRemove);
            setCart(newCart);
            localStorage.setItem('cart', JSON.stringify(newCart));
        };

        const handleClick = () => {
            const totalPrice = cart.reduce((total, item) => total + (item.price * item.quantity), 0);
            if (totalPrice) {
                navigate("/checkout", { state: { totalPrice } });
            }
        }

        return (
            <>
                <div className="boxcar-wrapper">
                    <MainHeader />
                    <section className="cart-page layout-radius">
                        <div className="boxcar-container">
                            <div className="boxcar-title-three">
                                <ul className="breadcrumb">
                                    <li><a href="#">Home</a></li>
                                    <li><span>Cart</span></li>
                                </ul>
                                <h2>Shop Cart</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="cart-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Sr No</th>
                                                    <th>Car Model</th>
                                                    <th>Selected Color</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Subtotal</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.car_model_name}</td>
                                                        <td style={{ backgroundColor: item.selectedColor }}>
                                                            {item.selectedColor}
                                                        </td>
                                                        <td>₹{item.price}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>₹{item.price * item.quantity}</td>
                                                        <td>
                                                            <button onClick={() => handleRemoveItem(index)}>
                                                                <img src={remove} alt="Remove" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="cart-totals">
                                        <h3>Cart Totals</h3>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Subtotal</th>
                                                    <td>₹{cart.reduce((total, item) => total + (item.price * item.quantity), 0)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Total</th>
                                                    <td>₹{cart.reduce((total, item) => total + (item.price * item.quantity), 0)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* <Link href="#" title="" className="theme-btn-web">Proceed to Checkout</Link>
                                         */}
                                          <div className="form-submit my-4">
                            <button type="submit" className="theme-btn w-100" onClick={handleClick}>Proceed to Checkout <img src="images/arrow.svg" alt="" /></button>
                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </>
        );
    };

    export default Cart;
