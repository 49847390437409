import React, { useEffect, useState } from "react";
import MainHeader from "../common/MainHeader";
import Footer from "../common/Footer";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import countryList from "react-select-country-list";
import { Country, State } from "country-state-city";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { data } from "jquery";

const Checkout = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const [cart, setCart] = useState(() => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  });
const shippingCost = 50
  const [totalPrice, setTotalPrice] = useState(
    location.state?.totalPrice || null
  );
  const countryOptions = countryList().getData();
  
  const [stateOptions, setStateOptions] = useState([]);
  const [checkOutData, setCheckOutData] = useState({addressId:"",totalCost:totalPrice+50,subTotal:totalPrice,shippingCost:50,paymentMode:0});

  const [addresses, setAddresses] = useState([]);
  const [editingAddressId, setEditingAddressId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // const user_id = localStorage.getItem("user_id")
      const response = await axios.get(`https://api.snppaints.in/user/getUsersAddresses/${user_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Replace with your API endpoint
      const data = response.data;
      console.log(data);
      if (data.status) {
        setAddresses(data.addresses);
      }
    };
    fetchData();
  }, []);

  console.log(cart, "cart");

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      country: "",
      state: "",
      house_number_street: "",
      apartment_suite_unit: "",
      city: "",
      pincode: "",
      phone: "",
      email: "",
      // additional_info: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      house_number_street: Yup.string().required(
        "House number and street name is required"
      ),
      pincode: Yup.string().required("Zip Code is required"),
      phone: Yup.string().required("Phone number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      try {
        console.log("Form data", values);
        // return
        const url = editingAddressId
          ? `https://api.snppaints.in/user/editUsersAddress/${editingAddressId}`
          : `https://api.snppaints.in/user/addUsersAddress/${user_id}`;
        const res = await axios.post(url, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = res.data;

        // Handle the response
        if (data.status) {
          console.log(data.message, "data.message");

          const notify = () => toast.success(data.message);
          notify();
          // console.log('Address added successfully:', data.data);
          // You can also clear the form or provide user feedback here
          const response = await axios.get(
            `https://api.snppaints.in/user/getUsersAddresses/${user_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ); // Replace with your API endpoint
          const data2 = response.data;
          console.log(data2);
          if (data2.status) {
            setAddresses(data2.addresses);
          }
          formik.resetForm();
        } else {
          const notify = () => toast.success(data.message);
          notify();
          console.log("Error adding address:", data.data);
        }
      } catch (error) {
        console.log(error, "err");
        const errMsg = error.response.data.message;
        const notify = () => toast.error(errMsg);
        notify();
      }
    },
  });

  const handleCountryChange = (option) => {
    console.log(option, "optins");

    formik.setFieldValue("country", option.label);
    const states = State.getStatesOfCountry(option.value).map((state) => ({
      label: state.name,
      value: state.isoCode,
    }));
    setStateOptions(states);
  };

  const handleZipCodeChange = async (e) => {
    const zipCode = e.target.value;
    formik.setFieldValue("pincode", zipCode);

    if (zipCode.length === 6) {
      try {
        const response = await axios.get(
          `https://api.postalpincode.in/pincode/${zipCode}`
        );
        const postOffice = response.data[0]?.PostOffice[0];
        if (postOffice) {
          console.log(postOffice, "postOffice");

          formik.setFieldValue("city", postOffice.Name);
          const stateName = postOffice.State;
          const country = "IN";
          // const country = postOffice.Country;

          formik.setFieldValue("country", country);

          // Find the state ISO code
          const stateIsoCode = State.getStatesOfCountry(country).find(
            (state) => state.name === stateName
          )?.isoCode;
          if (stateIsoCode) {
            formik.setFieldValue("state", stateIsoCode);
            const states = State.getStatesOfCountry(country).map((state) => ({
              label: state.name,
              value: state.isoCode,
            }));
            setStateOptions(states);
          }
        }
      } catch (error) {
        console.error("Error fetching state and country from pin code", error);
      }
    }
  };

  const checkoutHandler = async () => {

    if (!checkOutData.addressId) {
        toast.error('Address is required');
        return;
      }
    
      if (typeof checkOutData.totalCost !== 'number' || checkOutData.totalCost <= 0) {
        toast.error('Total cost must be a number greater than 0');
        return;
      }
    
      if (typeof checkOutData.subTotal !== 'number' || checkOutData.subTotal <= 0) {
        toast.error('Subtotal must be a number greater than 0');
        return;
      }
    
      if (typeof checkOutData.shippingCost !== 'number' || checkOutData.shippingCost < 0) {
        toast.error('Shipping cost must be a number and cannot be negative');
        return;
      }
    
      if (![0, 1].includes(checkOutData.paymentMode)) {
        toast.error('Invalid payment mode');
        return;
      }


    const {
      data: { key },
    } = await axios.get("https://api.snppaints.in/user/getkey", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log( {  userId:user_id,...checkOutData },' { order,userId:user_id,...checkOutData }');
    const {
      data: { order },
    } = await axios.post(
      "https://api.snppaints.in/user/checkout",
      { userId:user_id,...checkOutData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
console.log(order.id,'order.id');

    const options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "6 Pack Programmer",
      description: "Tutorial of RazorPay",
      image: "https://avatars.githubusercontent.com/u/25058652?v=4",
      order_id: order.id,
      // callback_url: "http://localhost:4000/api/paymentverification",
      handler: async (response) => {
        try {
            console.log(response.razorpay_order_id,'response.razorpay_order_id');
            console.log(response.razorpay_payment_id,'response.razorpay_payment_id');

            
          const user_id = localStorage.getItem("user_id");
          const { data } = await axios.post(
            "https://api.snppaints.in/user/paymentverification",
            {
              user_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (data.status) {
            const notify = () => toast.success("Payment successful");
            notify();
            // to("Payment successful");
            // Redirect or perform another action after successful payment
            // window.location.href = `/paymentsuccess?reference=${response.razorpay_payment_id}`;
          } else {
            alert("Payment verification failed");
            // Handle payment verification failure
          }
        } catch (error) {
          console.error("Error during payment verification:", error);
          alert("An error occurred during payment verification.");
        }
      },
      prefill: {
        name: "Gaurav Kumar",
        email: "gaurav.kumar@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  const handleDeleteAddress = async (id) => {
    try {
      const res = await axios.delete(`https://api.snppaints.in/user/deleteUsersAddress/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = res.data;

      if (data.status) {
        const notify = () => toast.success(data.message);
        notify();
      }

      setAddresses(addresses.filter((address) => address._id !== id));
    } catch (error) {
      const errMsg = error.response.data.message;
      const notify = () => toast.error(errMsg);
      notify();
      console.error("Failed to delete address", error);
    }
  };

  const handleEditAddress = async (id) => {
    try {
      console.log(id, "id");

      const res = await axios.get(`https://api.snppaints.in/user/getAddresse/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = res.data;

      if (data.status) {
        //  const notify = () => toast.success(data.message);
        //  notify();
        const addressData = data.data;
        console.log(addressData, "addressData");
        console.log(addressData.state, "addressData.state");
        console.log(typeof addressData.country, "addressData.country");

        // const stateIsoCode = State.getStatesOfCountry(addressData.country).find(state => state.name === addressData.state)?.isoCode;
        // console.log(stateIsoCode,'stateIsoCode');

        // if (stateIsoCode) {
        // formik.setFieldValue('state', stateIsoCode);
        const states = State.getStatesOfCountry(addressData.country).map(
          (state) => ({
            label: state.name,
            value: state.isoCode,
          })
        );
        console.log(states, "states");

        setStateOptions(states);
        // }

        formik.setValues({
          first_name: addressData.first_name || "",
          last_name: addressData.last_name || "",
          country: addressData.country || "",
          state: addressData.state || "",
          house_number_street: addressData.house_number_street || "",
          apartment_suite_unit: addressData.apartment_suite_unit || "",
          city: addressData.city || "",
          pincode: addressData.pincode || "",
          phone: addressData.phone || "",
          email: addressData.email || "",
        });
        setEditingAddressId(id);
      }

      //  setAddresses(addresses.filter(address => address.id !== id));
    } catch (error) {
      const errMsg = error.response.data.message;
      const notify = () => toast.error(errMsg);
      notify();
      console.error("Failed to delete address", error);
    }
  };

  const handleAddAddress = () => {};

  const handleSelectAddress = (id) => {
    setCheckOutData({...checkOutData,addressId:id})
  };
  console.log(checkOutData, "checkOutData");

  return (
    <>
      <Toaster />
      <div className="boxcar-wrapper">
        <MainHeader />
        <section className="checkout-section layout-radius">
          <div className="boxcar-container">
            <div className="boxcar-title-three">
              <ul className="breadcrumb">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <span>Cars for Sale</span>
                </li>
              </ul>
              <h2>Shop Checkout</h2>
            </div>
            {/* <div className="order-coupon">
                                <input type="text" placeholder="Coupon code" />
                                <button className="theme-btn" onClick={checkoutHandler}>Apply Coupon</button>
                            </div> */}
            <div className="row">
              <div className="content-column col-lg-8 col-md-12 col-sm-12">
                <div className="inner-column">
                  <h6 className="title">Add Billing Address</h6>
                  <form className="row g-0" onSubmit={formik.handleSubmit}>
                    <div className="form-column col-lg-6 mb-4">
                      <div className="form_boxes mb-0">
                        <label>First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          placeholder="Enter First Name"
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.first_name && formik.errors.first_name ? (
                        <div className="error text-danger">
                          {formik.errors.first_name}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-column col-lg-6 mb-4">
                      <div className="form_boxes mb-0">
                        <label>Last Name</label>
                        <input
                          type="text"
                          name="last_name"
                          placeholder="Enter Last Name"
                          value={formik.values.last_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.last_name && formik.errors.last_name ? (
                        <div className="error mb-3 text-danger">
                          {formik.errors.last_name}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-column col-lg-12 mb-4">
                      <div className="form_boxes mb-0">
                        <label>Zip Code</label>
                        <input
                          type="text"
                          name="pincode"
                          placeholder="Enter Zip Code"
                          value={formik.values.pincode}
                          onChange={handleZipCodeChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.pincode && formik.errors.pincode ? (
                        <div className="error text-danger">
                          {formik.errors.pincode}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-column col-lg-12">
                      <div className="form_boxes">
                        <label>Country / Region *</label>
                        <Select
                          options={countryOptions}
                          name="country"
                          value={countryOptions.find(
                            (option) => option.value === formik.values.country
                          )}
                          onChange={handleCountryChange}
                          onBlur={formik.handleBlur}
                          // isDisabled={true}
                        />
                        {formik.touched.country && formik.errors.country ? (
                          <div className="error">{formik.errors.country}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-column col-lg-12 mb-4">
                      <div className="form_boxes mb-0">
                        <label>State</label>
                        <Select
                          options={stateOptions}
                          name="state"
                          value={stateOptions.find(
                            (option) => option.value === formik.values.state
                          )}
                          onChange={(option) =>
                            formik.setFieldValue("state", option.value)
                          }
                          onBlur={formik.handleBlur}
                          // isDisabled={true}
                        />
                        {formik.touched.state && formik.errors.state ? (
                          <div className="error text-danger">
                            {formik.errors.state}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-column col-lg-12 mb-4">
                      <div className="form_boxes mb-0">
                        <label>House number and street name</label>
                        <input
                          type="text"
                          name="house_number_street"
                          placeholder="Enter House Number and Street Name"
                          value={formik.values.house_number_street}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.house_number_street &&
                      formik.errors.house_number_street ? (
                        <div className="error text-danger ">
                          {formik.errors.house_number_street}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-column col-lg-12">
                      <div className="form_boxes">
                        <label>Apartment, suite, unit, etc. (optional)</label>
                        <input
                          type="text"
                          name="apartment_suite_unit"
                          placeholder="Enter Apartment, Suite, Unit (Optional)"
                          value={formik.values.apartment_suite_unit}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-column col-lg-12 mb-4">
                      <div className="form_boxes mb-0">
                        <label>Town / City</label>
                        <input
                          type="text"
                          name="city"
                          placeholder="Auto-detected based on Zip Code"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.city && formik.errors.city ? (
                        <div className="error text-danger">
                          {formik.errors.city}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-column col-lg-6 mb-4">
                      <div className="form_boxes mb-0">
                        <label>Phone</label>
                        <input
                          type="text"
                          name="phone"
                          placeholder="Enter Phone Number"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="error text-danger">
                          {formik.errors.phone}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-column col-lg-6">
                      <div className="form_boxes mb-0">
                        <label>Email Address</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter Email Address"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error text-danger">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="form-column col-lg-12">
                                    <h6 className="title">Additional information</h6>
                                    <div className="form_boxes v2">
                                        <label>Order Notes (optional)</label>
                                        <div className="drop-menu">
                                            <textarea name="text" placeholder="Lorem Ipsum Dolar Sit Amet"></textarea>
                                        </div>
                                    </div>
                                </div> */}
                    <div className="form-column col-lg-6">
                      <div className="form-submit my-4">
                        <button type="submit" className="theme-btn w-100">
                          {editingAddressId ? "Edit" : "Add"} address{" "}
                          <img src="images/arrow.svg" alt="" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="address-side-bar">
                  <h6 className="address-title">Your Addresses</h6>
                  <ul className="address-list">
                    {addresses.length > 0 ? (
                      addresses.map((address, index) => (
                        <li key={address._id} className="address-item">
                          <div className="address-box">
                            <input
                              type="radio"
                              name="selectedAddress"
                              value={address._id}
                              id={`address-${index}`}
                              onChange={() => handleSelectAddress(address._id)}
                            />
                            <label
                              htmlFor={`address-${index}`}
                              className="address-label"
                            >
                              <p>
                                {address.house_number_street},
                                {address.apartment_suite_unit}, {address.city},{" "}
                                {address.state}, {address.pincode}
                              </p>
                            </label>
                            <div className="address-actions">
                              <button
                                onClick={() => handleEditAddress(address._id)}
                                className="address-edit-btn"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeleteAddress(address._id)}
                                className="address-delete-btn"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <p className="address-empty">Address not found</p>
                    )}
                  </ul>
                  {editingAddressId && (
                    <button
                      className="address-add-btn"
                      onClick={() => {
                        formik.resetForm();
                        setEditingAddressId(null);
                      }}
                    >
                      Add New Address
                    </button>
                  )}
                </div>

                <div className="side-bar mt-3">
                  <div className="order-box">
                    <h6 className="title">Your order</h6>

                    <ul className="order-list">
                      <li>
                        Product<span>Subtotal</span>
                      </li>
                      {cart.map((product, index) => {
                        return(<li className="v2">
                          {product.modelName} ---- ₹{product.price}x
                          {product.quantity}
                          <span>₹{product.price * product.quantity}</span>
                        </li>)
                      })}
                      {/* <li className="v2 v3">Road and Track Suspensio x 3<span>$67.00</span></li> */}
                      <li>
                        Subtotal<span>₹{checkOutData.subTotal}</span>
                      </li>
                      <li>
                        Shipping<span>₹{checkOutData.shippingCost}</span>
                      </li>
                      <li>
                        Total<span>₹{checkOutData.totalCost}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="payment-options">
                    <ul>
                      {/* <li>
                        <div className="shipp">
                          <input type="radio" id="c4" name="cc2" />
                          <label for="c4">
                            <span></span>
                            <small>Direct Bank Transfer</small>
                          </label>
                        </div>
                        <p>
                          Make your payment directly into our bank account.
                          Please use your Order ID as the payment reference.
                          Your order won’t be shipped until the funds have
                          cleared in our account.
                        </p>
                      </li> */}
                      <li>
                        <div className="shipp">
                          <input type="radio" id="c5" name="cc2" onClick={()=>{setCheckOutData({...checkOutData,paymentMode:0})}} />
                          <label for="c5">
                            <span></span>
                            <small>Online</small>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="shipp">
                          <input type="radio" id="c6" name="cc2"  onClick={()=>{setCheckOutData({...checkOutData,paymentMode:1})}}/>
                          <label for="c6">
                            <span></span>
                            <small>Cash on Delivery</small>
                          </label>
                        </div>
                      </li>
                      {/* <li>
                        <div className="shipp">
                          <input type="radio" id="c7" name="cc2" />
                          <label for="c7">
                            <span></span>
                            <small>PayPal</small>
                          </label>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                  <div className="form-submit">
                    <button type="button" className="theme-btn w-100" onClick={checkoutHandler}>
                      Place order <img src="images/arrow.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Checkout;
