import React from 'react'
import './App.css'
import Home from './Component/Main/Home'
import Login from './Component/Authentication/Login'
import Checkout from './Component/Main/Checkout'
import Cart from './Component/Main/Cart'
import CarBrand from './Component/Main/CarBrand'
import AboutUs from './Component/Main/AboutUs'
import ContactUs from './Component/Main/ContactUs'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Profile from './Component/Main/Profile'
import CarDetails from './Component/Main/CarDetails'

const App = () => {
  return (
    <>
      <BrowserRouter >
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/car_brand" element={<CarBrand />} />
        <Route path="/car_brand/:brandName" element={<CarBrand />} />
        <Route path="/car_brand/:brandName/:maincarid" element={<CarBrand />} />
        <Route path="/car_brand/:brandName/:maincarid/:car_detail" element={<CarBrand />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/car_details" element={<CarDetails/>} />
        <Route path="/cars/" element={<CarDetails/>} />
        
        </Routes>
      </BrowserRouter>
      {/* <Home/> */}
      {/* <Login/> */}
      {/* <Checkout/> */}
      {/* <Cart/> */}
      {/* <CarBrand/> */}
      {/* <AboutUs/> */}
      {/* <ContactUs /> */}

    </>
  )
}

export default App
